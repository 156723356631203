.checkboxContainer {
  padding-left: 16px !important;
}

.dateRangePicker {
  z-index: 4;
}

.radioGroupContainer {
  display: flex;
  justify-content: flex-end;

  &>div {
    background-color: transparent;

    & [class*='MuiFormControlLabel-label'] {
      font-size: 0.9rem;
    }
  }
}